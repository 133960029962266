import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '@sevensenders/component-library';
import { ReactComponent as InfoIcon } from '../../../icons/info.svg';
import { ReactComponent as ArrowRightIcon } from '../../../icons/arrow-right.svg';
import * as consignmentActions from '../../../store/consignments/actions';
import './HistoryCell.css';

export default function HistoryCell({ value, row }) {
  const dispatch = useDispatch();
  const [history, setHistory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = row.original;

  const typeMapping = {
    PLANNED_QUANTITY: 'Planned quantity',
    ADJUSTED_PLANNED_QUANTITY: 'Adjusted planned quantity',
    PICKED_UP_QUANTITY: 'Actual quantity',
    DROPPED_OFF_QUANTITY: 'Dropped off quantity',
  };

  const fetchHistory = () => {
    setIsLoading(true);
    dispatch(consignmentActions.fetchHistory(id))
      .then((response) => {
        setHistory(response);
        setIsModalOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={fetchHistory}
        variant="plain"
        style={{ width: 28, cursor: isLoading ? 'wait' : 'pointer' }}
        disabled={isLoading}
      >
        <InfoIcon
          width="24"
          height="24"
          style={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? 0.5 : 1 }}
        />
      </Button>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="History">
          <div className="cs-history">
            {history?.quantity?.length > 0 && (
              <>
                <h3>Quantity Changes</h3>
                <ul className="timeline">
                  {history.quantity.map((entry, index) => (
                    <li key={`quantity-${index}`}>
                      <div className="timeline-badge station">
                        <span className="text-sm text-black">{entry.shortDate}</span>
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h5 className="timeline-title">
                            {entry.by} - {entry.date}
                          </h5>
                          <h4>
                            <span className="label-type">{typeMapping[entry.type] || entry.type}</span>
                            <span className="status label label-quantity">{entry.from}</span>
                            <ArrowRightIcon width="24" height="20" />
                            <span className="status label label-quantity">{entry.to}</span>
                          </h4>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {history?.status?.length > 0 && (
              <>
                <h3>Status Timeline</h3>
                <ul className="timeline">
                  {history.status.map((entry, index) => (
                    <li key={`status-${index}`}>
                      <div className="timeline-badge station">
                        <span className="text-sm text-black">{entry.shortDate}</span>
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h5 className="timeline-title">
                            {entry.by} - {entry.date}
                          </h5>
                          <h4>
                            <span className={'status label label-' + entry.from}>{entry.from}</span>
                            <ArrowRightIcon width="24" height="20" />
                            <span className={'status label label-' + entry.to}>{entry.to}</span>
                          </h4>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {!history?.status?.length && !history?.quantity?.length && <p>No history available.</p>}
          </div>
        </Modal>
      )}
    </>
  );
}

HistoryCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  }).isRequired,
};
