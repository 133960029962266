import StatusCell from '../containers/ConsignmentsTable/Cells/StatusCell';
import ConsignmentLinkCell from '../containers/ConsignmentsTable/Cells/ConsignmentLinkCell';
import DefaultCell from '../containers/ConsignmentsTable/Cells/DefaultCell';
import QuantityEditCell from '../containers/ConsignmentsTable/Cells/QuantityEditCell';
import DatetimeEditCell from '../containers/ConsignmentsTable/Cells/DatetimeEditCell';
import StationCell from '../containers/ConsignmentsTable/Cells/StationCell';
import DestinationCell from '../containers/ConsignmentsTable/Cells/DestinationCell';
import CustomersCell from '../containers/ConsignmentsTable/Cells/CustomersCell';
import HistoryCell from '../containers/ConsignmentsTable/Cells/HistoryCell';
import {
  canReportDropOffQuantity,
  canReportPickupQuantity,
  canReportPlannedQuantity,
  canUpdateDropOffTime,
  canUpdatePickupTime,
  canReportTotalParcelsQuantityInbound,
  canReportTotalParcelsQuantityOutbound,
  isLsp,
} from '../utils/consignments';
import { actions as consignmentActions } from '../store/consignments';
import { formatDatetime, isDateFuture } from '../utils/datetime';
import { actions as loaderActions } from '../store/loader';
import { actions as alertActions } from '../store/alerts';
import { actions as popupActions } from '../store/popup';

export const TYPES = {
  OUTBOUND: 0,
  INBOUND: 1,
};

export const SCOPE = {
  PLANNED: 0,
  ACTUAL: 1,
};

export const columnsConfig = [
  {
    Header: '',
    accessor: 'status',
    Cell: StatusCell,
    width: 31,
    style: { verticalAlign: 'middle', textAlign: 'center' },
  },
  {
    Header: 'consignments:table.columns.planned_picked_up',
    accessor: 'planned_pick_up_time',
    Cell: ConsignmentLinkCell,
    width: 100,
  },
  {
    Header: 'consignments:table.columns.planned_drop_off',
    accessor: 'planned_drop_off_time',
    Cell: ConsignmentLinkCell,
    width: 100,
  },
  {
    Header: 'consignments:table.columns.shipper_public_name',
    accessor: isLsp() ? 'relation.offer_relations' : 'partner.public_name',
    Cell: isLsp() ? CustomersCell : DefaultCell,
    width: 150,
  },
  {
    Header: 'consignments:table.columns.from',
    accessor: 'origin_loading_location.station',
    Cell: StationCell,
    width: 100,
  },
  {
    Header: 'consignments:table.columns.to',
    accessor: 'destination_loading_location.station',
    Cell: DestinationCell,
    width: 100,
  },
  {
    Header: 'consignments:table.columns.lmc',
    accessor: 'lmc.public_name',
    Cell: DefaultCell,
    width: 100,
  },
  {
    Header: 'consignments:table.columns.license_plate',
    accessor: 'vehicle_license.pickup',
    Cell: DefaultCell,
    width: 100,
  },
  {
    Header: 'consignments:table.columns.license_plate',
    accessor: 'vehicle_license.drop_off',
    Cell: DefaultCell,
    width: 100,
  },
  {
    Header: 'consignments:table.columns.planned_qty',
    accessor: 'quantities.PAL.planned_quantity',
    Cell: QuantityEditCell,
    width: 100,
    style: { zIndex: 1 },
    subText: 'PAL',
    isEditable: ({ rowData }) => canReportPlannedQuantity(rowData, 'PAL'),
    onSubmit:
      (dispatch) =>
      ({ rowData: consignment, value }) => {
        if (value === 0) {
          dispatch(
            popupActions.open(
              'columnsConfig',
              'consignments:action.dismiss_consignment',
              'consignments:planned_qty_not_be_zero',
              () => {
                dispatch(loaderActions.setLoading(true));
                const [promise] = dispatch(consignmentActions.dismissConsignment(consignment));
                promise.finally(() => {
                  dispatch(loaderActions.setLoading(false));
                });
              },
              () => {}
            )
          );
        } else {
          dispatch(loaderActions.setLoading(true));
          const [promise] = dispatch(
            consignmentActions.updateQuantity(consignment, 'PAL', { planned_quantity: value })
          );
          promise.finally(() => {
            dispatch(loaderActions.setLoading(false));
          });
        }
      },
  },
  {
    Header: 'consignments:table.columns.planned_qty',
    accessor: 'quantities.PAL.expected_quantity',
    Cell: QuantityEditCell,
    width: 100,
    style: { zIndex: 1 },
    subText: 'PAL',
    isEditable: false,
  },
  {
    Header: 'consignments:table.columns.actual_picked_up',
    accessor: 'actual_pick_up_time',
    Cell: DatetimeEditCell,
    isEditable: ({ rowData }) => canUpdatePickupTime(rowData),
    onSubmit:
      (dispatch) =>
      ({ rowData: consignment, value }) => {
        if (!isDateFuture(value)) {
          const date = formatDatetime(value);
          dispatch(loaderActions.setLoading(true));
          const [promise] = dispatch(
            consignmentActions.updateConsignmentData(consignment, { actual_pick_up_time: date })
          );
          promise.finally(() => {
            dispatch(loaderActions.setLoading(false));
          });
        } else {
          dispatch(alertActions.add('columnsConfig', 'error', 'consignments:actual_pickup_time_is_not_valid'));
        }
      },
  },
  {
    Header: 'consignments:table.columns.actual_drop_off',
    accessor: 'actual_drop_off_time',
    Cell: DatetimeEditCell,
    isEditable: ({ rowData }) => canUpdateDropOffTime(rowData),
    onSubmit:
      (dispatch) =>
      ({ rowData: consignment, value }) => {
        const date = formatDatetime(value);
        dispatch(loaderActions.setLoading(true));
        const [promise] = dispatch(
          consignmentActions.updateConsignmentData(consignment, { actual_drop_off_time: date })
        );
        promise.finally(() => {
          dispatch(loaderActions.setLoading(false));
        });
      },
  },
  {
    Header: 'consignments:table.columns.actual_qty',
    accessor: 'quantities.PAL.picked_up_quantity',
    Cell: QuantityEditCell,
    width: 100,
    style: { zIndex: 1 },
    subText: 'PAL',
    isEditable: ({ rowData }) => canReportPickupQuantity(rowData),
    onSubmit:
      (dispatch) =>
      ({ rowData: consignment, value }) => {
        if (consignment.actual_pick_up_time != null) {
          // The value shouldn't exceed the capacity of the transport , capacity has to be less than 34
          if (value < 34) {
            dispatch(loaderActions.setLoading(true));
            const [promise] = dispatch(
              consignmentActions.updateQuantity(consignment, 'PAL', { picked_up_quantity: value })
            );
            promise.finally(() => {
              dispatch(loaderActions.setLoading(false));
            });
          } else {
            dispatch(alertActions.add('columnsConfig', 'error', 'consignments:actual_qty_has_to_be_less_value'));
          }
        } else {
          dispatch(alertActions.add('columnsConfig', 'error', 'consignments:actual_pickup_time_is_not_null'));
        }
      },
  },
  {
    Header: 'consignments:table.columns.actual_qty',
    accessor: 'quantities.PAL.dropped_off_quantity',
    Cell: QuantityEditCell,
    width: 100,
    style: { zIndex: 1 },
    subText: 'PAL',
    isEditable: ({ rowData }) => canReportDropOffQuantity(rowData),
    onSubmit:
      (dispatch) =>
      ({ rowData: consignment, value }) => {
        dispatch(loaderActions.setLoading(true));
        const [promise] = dispatch(
          consignmentActions.updateQuantity(consignment, 'PAL', { dropped_off_quantity: value })
        );
        promise.finally(() => {
          dispatch(loaderActions.setLoading(false));
        });
      },
  },
  {
    Header: 'consignments:table.columns.total_parcel_qty',
    accessor: 'total_parcels_quantity',
    Cell: QuantityEditCell,
    style: { zIndex: 1 },
    width: 175,
    subText: 'PAK',
    isEditable: ({ type, rowData }) => {
      return type === TYPES.INBOUND
        ? canReportTotalParcelsQuantityInbound(rowData)
        : canReportTotalParcelsQuantityOutbound(rowData);
    },
    onSubmit:
      (dispatch) =>
      ({ type, rowData: consignment, value }) => {
        dispatch(loaderActions.setLoading(true));
        const [promise] = dispatch(
          consignmentActions.updateConsignmentData(consignment, { total_parcels_quantity: value })
        );
        promise.finally(() => {
          dispatch(loaderActions.setLoading(false));
        });
      },
  },
  {
    Header: 'consignments:table.columns.loading_reference',
    accessor: 'reference_id',
    Cell: DefaultCell,
  },
  {
    Header: 'History',
    accessor: 'history',
    Cell: HistoryCell,
  },
];

const inboundHiddenColumns = [
  'planned_pick_up_time',
  'actual_pick_up_time',
  'quantities.PAL.planned_quantity',
  'quantities.PAL.picked_up_quantity',
  'vehicle_license.pickup',
];
const outboundHiddenColumns = [
  'planned_drop_off_time',
  'actual_drop_off_time',
  'quantities.PAL.expected_quantity',
  'quantities.PAL.dropped_off_quantity',
  'vehicle_license.drop_off',
];
const lspColumn = ['partner.public_name'];
export const getHiddenColumns = (isInbound = false) => (isInbound ? inboundHiddenColumns : outboundHiddenColumns);
export const getLspColumn = (isLsp = false) => (!isLsp ? lspColumn : []);
