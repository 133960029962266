import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import config from '../../config';
import API, { createCancelToken } from '../../api';
import { TYPES } from '../../config/consignments';
import storage from '../../utils/storage';
import { formatDatetime } from '../../utils/datetime';

export const types = {
  SET_CONSIGNMENT: 'SET_CONSIGNMENT',
  SET_CONSIGNMENTS: 'SET_CONSIGNMENTS',
  UPDATE_CONSIGNMENT: 'UPDATE_CONSIGNMENT',
  SET_LOADING_CONSIGNMENTS: 'SET_LOADING_CONSIGNMENTS',
  SET_LOADING_CONSIGNMENT: 'SET_LOADING_CONSIGNMENT',
  ADD_INBOUND_FILTER: 'ADD_INBOUND_FILTER',
  REMOVE_INBOUND_FILTER: 'REMOVE_INBOUND_FILTER',
  ADD_OUTBOUND_FILTER: 'ADD_OUTBOUND_FILTER',
  REMOVE_OUTBOUND_FILTER: 'REMOVE_OUTBOUND_FILTER',
  SET_OUTBOUND_FILTERS: 'SET_OUTBOUND_FILTERS',
  SET_INBOUND_FILTERS: 'SET_INBOUND_FILTERS',
};

export const setConsignments = (consignments) => ({
  type: types.SET_CONSIGNMENTS,
  payload: consignments,
});

export const setConsignment = (consignment) => ({
  type: types.SET_CONSIGNMENT,
  payload: consignment,
});

export const setConsignmentById = (id) => (dispatch, getState) => {
  const { consignments } = getState();

  const consignmentList = consignments?.normalized?.entities?.consignment;
  if (consignmentList && id in consignmentList) {
    const consignment = consignmentList[id];
    const { syncedAt } = consignment;
    let isValidCache = true;

    // reload consignment
    if (syncedAt) {
      const diff = (Date.now() - syncedAt) / 1000;
      isValidCache = diff <= config.ttl.list;
    }

    isValidCache && dispatch(setConsignment(consignment));
  }
};

const setLoadingConsignments = (isLoading) => ({
  type: types.SET_LOADING_CONSIGNMENTS,
  payload: isLoading,
});

const setLoadingConsignment = (isLoading) => ({
  type: types.SET_LOADING_CONSIGNMENT,
  payload: isLoading,
});

export const getConsignment = (id) => (dispatch) => {
  const canceller = createCancelToken();

  dispatch(setConsignment(null));
  dispatch(setLoadingConsignment(true));

  API.getConsignment(id, canceller)
    .then(({ data }) => {
      dispatch(setConsignment(data));
    })
    .catch(() => {
      dispatch(setLoadingConsignment(false));
    });

  return canceller;
};

export const getFilters = (type) => (_, getState) => {
  const {
    consignments: { filters },
  } = getState();

  const isInbound = type === TYPES.INBOUND;
  const direction = isInbound ? 'inbound' : 'outbound';
  const timeField = isInbound ? 'plannedDropOffTime' : 'plannedPickUpTime';

  const typeFilters = isInbound ? filters.inbound : filters.outbound;

  return {
    direction,
    itemsPerPage: 100,
    order: {
      [timeField + '.from']: 'ASC',
    },
    ...typeFilters,
  };
};

export const getConsignments = (type) => (dispatch) => {
  const canceller = createCancelToken();

  dispatch(setConsignments(null));
  dispatch(setLoadingConsignments(true));

  const payload = dispatch(getFilters(type));

  API.getConsignments(payload, canceller)
    .then(({ data }) => {
      dispatch(setConsignments(data));
    })
    .catch(({ response: data }) => {
      dispatch(setLoadingConsignments(false));
    });

  return canceller;
};

export const exportConsignments = (type) => (dispatch) => {
  const canceller = createCancelToken();
  const payload = dispatch(getFilters(type));

  const promise = API.exportConsignments(payload, canceller).then(({ data }) => {
    const filename = 'consignments.csv';
    // initiate as download
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([data]));
    a.setAttribute('download', filename);
    a.click();
  });

  return [promise, canceller];
};

export const updateConsignmentData = (consignment, data) => (dispatch) => {
  const { id, unit_quantities } = consignment;

  const payload = {
    id,
    unit_quantity_list: unit_quantities,
    ...data,
  };

  if (Object.keys(payload.unit_quantity_list).length === 0) {
    payload.unit_quantity_list = unit_quantities;
  }

  dispatch(setLoadingConsignment(true));
  const canceller = createCancelToken();
  const promise = API.updateConsignment(id, payload, canceller)
    .then(({ data }) =>
      dispatch({
        type: types.UPDATE_CONSIGNMENT,
        payload: data,
      })
    )
    .catch(() => {
      console.log('Error updating consignment');
    })
    .finally(() => {
      dispatch(setLoadingConsignment(false));
    });

  return [promise, canceller];
};

// fetch consignment history
export const fetchHistory = (id) => (dispatch) => {
  const canceller = createCancelToken();

  return API.getConsignmentHistory(id, canceller)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      console.log('Error fetching history');
    });
};

export const updateQuantity = (consignment, quantityType, data) => (dispatch) => {
  const { id, quantities } = consignment;
  const quantity = quantityType in quantities && quantities[quantityType];

  if (!quantity) {
    return [Promise.resolve(), null];
  }

  const payload = {
    id,
    unit_quantity_list: {
      [quantityType]: {
        ...quantity,
        ...data,
      },
    },
  };

  return dispatch(updateConsignmentData(consignment, payload));
};

export const dismissConsignment = (consignment) => (dispatch) => {
  const { id, quantities } = consignment;

  const newQuantities = Object.keys(quantities).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...quantities[key],
        planned_quantity: 0,
      },
    }),
    {}
  );

  const payload = {
    id,
    unit_quantity_list: newQuantities,
  };

  return dispatch(updateConsignmentData(consignment, payload));
};

const saveFilters = (type, state) => {
  const {
    consignments: { filters },
  } = state;
  storage.set(`filters.${type}`, JSON.stringify(filters[type]));
};

export const addInboundFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: types.ADD_INBOUND_FILTER,
    payload: filter,
  });

  saveFilters('inbound', getState());
};

export const removeInboundFilter = (filterName) => (dispatch, getState) => {
  dispatch({
    type: types.REMOVE_INBOUND_FILTER,
    payload: filterName,
  });
  saveFilters('inbound', getState());
};

export const addOutboundFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: types.ADD_OUTBOUND_FILTER,
    payload: filter,
  });

  saveFilters('outbound', getState());
};

export const removeOutboundFilter = (filterName) => (dispatch, getState) => {
  dispatch({
    type: types.REMOVE_OUTBOUND_FILTER,
    payload: filterName,
  });
  saveFilters('outbound', getState());
};

/**
 * Resolve date range from data to ISO8601 format, defaults to today's start and end of day
 * @param data
 * @param key
 * @returns {{before: string, after: string}}
 */
const loadFilterDatesByKey = (data, key) => {
  let dateFrom = parseISO(data[key]?.after);
  let dateTo = parseISO(data[key]?.before);

  if (!isValid(dateFrom) || !isValid(dateTo)) {
    dateFrom = startOfDay(new Date());
    dateTo = endOfDay(new Date());
  }

  return {
    after: formatDatetime(dateFrom),
    before: formatDatetime(dateTo),
  };
};

export const fetchFilters = () => (dispatch, getState) => {
  // Date filter
  let filtersOutbound = JSON.parse(storage.get('filters.outbound'));
  let filtersInbound = JSON.parse(storage.get('filters.inbound'));

  if (!filtersOutbound) {
    filtersOutbound = {};
  }

  if (!filtersInbound) {
    filtersInbound = {};
  }

  filtersOutbound.plannedPickUpTime = loadFilterDatesByKey(filtersOutbound, 'plannedPickUpTime');
  filtersInbound.plannedDropOffTime = loadFilterDatesByKey(filtersInbound, 'plannedDropOffTime');

  dispatch({
    type: types.SET_OUTBOUND_FILTERS,
    payload: filtersOutbound,
  });

  dispatch({
    type: types.SET_INBOUND_FILTERS,
    payload: filtersInbound,
  });

  const state = getState();
  saveFilters('outbound', state);
  saveFilters('inbound', state);
};
